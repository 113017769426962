import React from "react";
import {Link} from "gatsby";

const ThankYou = ({hideLaunchPopup, showPopup}) => {

    return (
        <div className={`popup ${showPopup ? 'popup-show' : 'popup-hidden'}`}>
            <div className={'popup-bg'} onClick={hideLaunchPopup}/>
            <div className={'popup-inner'}>
                <h5>Thank you</h5>

                <div className={'text-content'}>
                    You're on the waitlist! We'll reach out if you're chosen to participate in our Vaults beta program.
                </div>

                <div className={'buttons mt-6'}>
                    <div className={'flex justify-center'}>
                        <div className={'basis-auto px-2.5'}>
                            <Link to={'/'}>
                                <button className={'btn'}>Back to homepage</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThankYou;
