import * as React from "react";
import {Helmet} from "react-helmet";
import {Link} from "gatsby";
import {SEO} from "../components/seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import video1hero from "../videos/vault-poster.png";
import video1webm from "../videos/vault.webm";
import axios from "axios";
import ThankYou from "../components/thankYouPopup";
import {useEffect, useState} from "react";
import backImage from "../images/back.svg";

const VaultBetaPage = () => {

    const [showPopup,setShowPopup] = useState(false);
    const [applicationEnabled,setApplicationEnabled] = useState(false);

    const join = (e) =>{
        e.preventDefault();
        const url = `https://api.sendgrid.com/v3/marketing/contacts`
        var apiKey = `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`

        var email = document.getElementById('vault-email').value;
        var discord = document.getElementById('vault-discord').value;
        var participate = document.getElementById('vault-why-participate').value;

        const data =  {
            "list_ids":['73767d89-bb19-4332-8a81-71a03989c6e7'],
            "contacts":[
                {
                    "email":email,
                    "unique_name": discord,
                    "custom_fields":{
                        "e2_T": participate,
                    }
                }
            ]
        }

        try {
            const response = axios({
                method: 'PUT',
                url: url,
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                withCredentials: false
            }).then((res)=>{
                if(res.status === 202){
                    setShowPopup(true)
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

        return (
        <div className={'vault-beta-page'}>
            <Helmet>
                <title>Inter Protocol - Vault Beta</title>
            </Helmet>
            <Header/>

            <ThankYou showPopup={showPopup} showLaunchPopup={()=>setShowPopup(true)} hideLaunchPopup={()=>setShowPopup(false)}/>

            <div className={'section vault-section'}>
                <div className={'video-container'}>
                    <div className={'video'}>
                        <video autoPlay={true} preload={true} loop={true} playsInline={true} muted={true} poster={video1hero}>
                            <source src={video1webm} type="video/webm" />
                        </video>
                    </div>
                </div>
                <div className={'container'}>
                    <div className={'form-block'}>
                        <h3>Vaults <span>Beta</span></h3>
                        <div className={'bold-title'}>
                            Be the first to test the upcoming Vaults application!
                        </div>
                        <div className={'description'}>
                            As a beta tester, you'll get hands-on experience with a testnet version of Vaults. You'll be able to create a vault by depositing ATOM and minting IST, make changes to your vault's collateralization ratio, and much more!
                        </div>
                        <form method="get" id={'join'} target="_blank" onSubmit={applicationEnabled ? join : false}>
                            <div className={'form-group'}>
                                <label htmlFor="vault-email">Email</label>
                                <input type="email" name={'vault-email'} id={'vault-email'} required={true} placeholder={'Enter your email'}/>
                            </div>

                            <div className={'form-group'}>
                                <label htmlFor="vault-discord">Discord</label>
                                <input type="text" name={'vault-discord'} id={'vault-discord'} required={true} placeholder={'Enter your Discord ID (e.g. IST#1234)'}/>
                            </div>

                            <div className={'form-group'}>
                                <label htmlFor="vault-why-participate">Why do you want to participate?</label>
                                <input type="text" name={'vault-why-participate'} id={'vault-why-participate'} required={true} placeholder={'Enter your answer'}/>
                            </div>

                            <button type={applicationEnabled ? 'submit' : 'button'} disabled={applicationEnabled ? false : true}>{applicationEnabled ? 'Join waitlist' : 'Application Closed'}</button>
                        </form>

                        <div className={'close-date'}>
                            Registrations close March 3, 2023. Limited Space!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VaultBetaPage

export const Head = () => (
    <SEO title={'Inter Protocol - Vault Beta'} description={''}/>
)

